<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      ref="dataForm"
      :model="dataForm"
      :rules="dataRule"
      label-width="120px"
      @keyup.enter.native="dataFormSubmit()"
    >
      <el-form-item label="供应商" prop="orgId">
        <el-select
          v-model="dataForm.orgId"
          clearable
          filterable
          placeholder="请选择"
          :disabled="disabled"
        >
          <el-option
            v-for="(item, index) in orgList"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="经营许可证" prop="imageUrl">
        <el-img v-model="dataForm.imageUrl" :disabled="disabled" />
      </el-form-item>
      <el-form-item label="证件名称" prop="imageName">
        <el-input
          v-model="dataForm.imageName"
          :disabled="disabled"
          placeholder="证件名称"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { getOrgList } from '@/utils/options.js';
import { keys, pick } from 'lodash';
export default {
  data() {
    return {
      disabled: false,
      visible: false,
      orgList: [],
      dataForm: {
        id: 0,
        orgId: '',
        imageName: '',
        imageUrl: '',
      },
      dataRule: {
        orgId: [
          {
            required: true,
            message: '供应商不能为空',
            trigger: 'blur',
          },
        ],
        imageUrl: [
          {
            required: true,
            message: '许可证图片不能为空',
            trigger: 'blur',
          },
        ],
        imageName: [
          {
            required: true,
            message: '证件名称不能为空',
            trigger: 'blur',
          },
        ],
      },
    };
  },

  created() {},

  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.getOrgList();
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/tc/orglicense/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = pick(data.orgLicense, keys(this.dataForm));
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/tc/orglicense/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },

    //供应商列表
    getOrgList() {
      getOrgList(0, 1).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },
  },
};
</script>
